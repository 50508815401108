import React, { Component } from "react";
import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Employee from "../components/employee";
import Input from "../components/inputText";
import Textarea from "../components/textarea";
import Check from "../components/check";
import MarkdownRenderer from "react-markdown-renderer";
import { uniqWith, isEqual } from "lodash";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.pageContext.lang,
      hrefLang: this.props.pageContext.data.hrefLang,
      menu: this.props.pageContext.menu,
      footerMenu: this.props.pageContext.footerMenu,
      breadcrumbs: [
        {
          name: "Kontakt",
          href: "contact",
        },
      ],
      data: this.props.pageContext.data,
    };
  }
  render() {
    let {
        breadcrumbs,
        lang,
        hrefLang,
        data,
        menu,
        footerMenu,
      } = this.state,
      employess = [];
    this.props.pageContext.employees.map(
      ({ employee_section }) =>
        employess.push({
          items: [],
          sectionName: employee_section,
        })
    );
    employess = uniqWith(employess, isEqual);
    this.props.pageContext.employees.map((item) =>
      employess[
        employess.findIndex(
          (element) =>
            item.employee_section === element.sectionName
        )
      ].items.push(item)
    );
    return (
      <Layout
        menu={menu}
        footerMenu={footerMenu}
        title={data.seoTitle}
      >
        <div className="bg-gradient">
          <Header lang={lang} hrefLang={hrefLang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="section-header-image">
              <div className="text-holder">
                <header className="section-header red-line">
                  <h1>
                    <strong>{data.contact_h1}</strong>
                  </h1>
                </header>
                <p>{data.contact_description}</p>
              </div>
              <div className="image-holder">
                <img
                  src={require("../images/contact-header.svg")}
                  alt=""
                />
              </div>
            </section>
            <section className="contact-wrapper">
              <div className="contact-box box-white contact-info">
                <div className="info-box-wrapper">
                  <div className="info-box tel">
                    <p>{data.label_tel}</p>
                    <a href={`tel:${data.contact_tel1}`}>
                      {data.contact_tel1}
                    </a>
                    <a href={`tel:${data.contact_tel2}`}>
                      {data.contact_tel2}
                    </a>
                  </div>
                  {data.fax && (
                    <div className="info-box fax">
                      <p>{data.label_fax}</p>
                      <a href={`tel:${data.fax}`}>
                        {data.fax}
                      </a>
                    </div>
                  )}
                  <div className="info-box mail">
                    <p>{data.label_mail}</p>
                    <a href={`mailto:${data.contact_mail}`}>
                      {data.contact_mail}
                    </a>
                  </div>
                </div>
                <div className="company-info">
                  <h3 className="red-line line-md">
                    {data.label_company}
                  </h3>
                  <div className="company-address">
                    <MarkdownRenderer
                      markdown={data.contact_company}
                    />

                    <p className="details">
                      {data.contact_company_info}
                    </p>
                    <a
                      href={data.btn_map_href}
                      target="_blank"
                      className="btn-red"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../images/map-icon.svg")}
                        alt=""
                      />
                      <span>{data.btn_map_name}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="contact-box">
                {employess.map(
                  ({ sectionName, items }, index) => (
                    <div
                      className="employees-section-holder"
                      key={index}
                    >
                      <h3 className="red-line line-md">
                        {sectionName}
                      </h3>
                      {items.map(
                        ({
                          employee_position,
                          employee_name,
                          employee_tel,
                          employee_mail,
                          employee_img,
                          id,
                        }) => (
                          <Employee
                            key={id}
                            position={employee_position}
                            name={employee_name}
                            tel={employee_tel}
                            mail={employee_mail}
                            image={employee_img
                              .replace('["', "")
                              .replace('"]', "")}
                          />
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            </section>
          </div>
        </div>
        <div className="bg-gradient">
          <div className="main-wrapper-md">
            <section className="contact-form">
              <header className="red-line">
                <h2>
                  <strong>
                    {data.contact_form_h2.split(" ")[0]}
                  </strong>{" "}
                  {data.contact_form_h2.split(" ").pop()}
                </h2>
                <p>{data.contact_form_description}</p>
              </header>
              <div className="form-wrapper">
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/pl/dziekujemy"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact"
                  />
                  <div className="input-wrapper">
                    <Input
                      required={true}
                      placeholder={data.contact_form_name}
                      id="name"
                      type="text"
                    />
                    <Input
                      placeholder={data.contact_form_tel}
                      id="phone"
                      type="tel"
                    />
                    <Input
                      required={true}
                      placeholder={data.contact_form_mail}
                      id="mail"
                      type="mail"
                    />
                  </div>
                  <Textarea
                    required={true}
                    id="msg"
                    name="message"
                    placeholder={data.contact_form_msg}
                  />
                  <div className="form-bottom">
                    <div className="checks-box">
                      <Check
                        id="accept2"
                        name="zgoda1"
                        label={data.contact_form_agree2}
                      />
                      <Check
                        id="accept1"
                        name="zgoda2"
                        label={data.contact_form_agree1}
                      />
                      <div data-netlify-recaptcha="true"></div>
                    </div>

                    <div className="button-box">
                      <button
                        type="submit"
                        className="btn-red"
                      >
                        <span>
                          <strong>
                            {data.btn_send_name}
                          </strong>
                        </span>
                        <img
                          src={require("../images/arrow-button.svg")}
                          style={{ width: "23px" }}
                          alt="white arrow"
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Contact;
